<template>
  <div class="flex flex-col w-full">
    <strong>Opvolg acties:</strong>

    <UILoading v-if="loading" sm />
    <div v-if="data?.count <= 0" class="no-data text-center">
      Geen opvolg acties
    </div>
    <div v-else class="text-center">
      <ul class="flex flex-col gap-0.5 mt-1">
        <li v-for="item in filteredItems" :key="item.id" class="card p-0 gap-2 flex justify-between items-center">
          <p class="block whitespace-normal">
            {{ item.content }}
          </p>
          <small class="italic text-gray-400">({{ dateString(item.creation_date) }})</small>
        </li>
      </ul>
      <button v-if="data?.count > 1" class="btn xs text-xs mx-auto" @click="showAll = !showAll">
        {{ showAll ? 'Toon minder' : `Toon alles (${data.count})` }}
      </button>
    </div>

    <div class="flex flex-col items-start gap-2 mt-4">
      <UIInput
        v-model="textInhoud"
        class="w-full max-w-3xl !bg-white"
        rows="4"
        placeholder="Ondernomen actie ..."
      />
      <button :disabled="loading" class="btn success text-white" @click="handleOpslaan">
        Opslaan
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, ref } from 'vue'

import UIInput from '@/components/UI/Input/Input'
import UILoading from '@/components/UI/Loading.vue'

import { dateString } from '@/functions/formatDate'
import useApi from '@/hooks/useApi'
import useGetApi from '@/hooks/useGetApi'

const props = defineProps({
  rit_id: Number,
  opvolg_acties: {
    type: Array,
  },
  minimumShow: {
    type: Number,
    default: 1,
  },
})

const textInhoud = ref('')
const showAll = ref(false)

const api = useApi()
const { data, getData: refresh, loading } = useGetApi('RIT_OPVOLG_ACTIES_LIST', { rit_id: props.rit_id }, {
  skipMounted: Array.isArray(props.opvolg_acties),
})

if (Array.isArray(props.opvolg_acties)) {
  data.value = {
    list: [...props.opvolg_acties].slice(0),
    count: Number(props.opvolg_acties.length),
  }
}

const filteredItems = computed(() => {
  const list = data.value?.list || []

  if (!list.length) return []
  if (showAll.value) return list

  const result = []
  for (let i = list.length - 1; i >= 0; i--) {
    if (result.length == props.minimumShow) break
    result.push(list[i])
  }
  return result
})

const handleOpslaan = () => {
  const data = {
    rit_id: props.rit_id,
    content: textInhoud.value,
  }

  // const isEdit = beschikbaarheid.value?.id > 0
  // if (isEdit) data.id = beschikbaarheid.value.id
  // const endpoint = (isEdit > 0 ? 'BESCHIKBAARHEID_BEWERKEN' : 'BESCHIKBAARHEID_CREATE')

  const endpoint = 'RIT_OPVOLG_ACTIES_CREATE'

  api(endpoint, data).then(() => {
    refresh()
  })
}
</script>
